/* NAVBAR STYLES */
.nav-section {
  /* border: 1px solid red; */
}
.nav-section .logo-text a {
  color: #000000;
  font-weight: 700;
}
.nav-section .flex-container-2 > * {
  flex-basis: auto;
  margin-bottom: 0;
}
.nav-section .flex-container-2 {
  display: flex;
  flex-direction: row wrap;
  justify-content: space-between;
  /* border: 1px solid green; */
  position: relative;
  align-items: center;
}

.menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100vh;
  /* background-color: #fff; */
  transform-origin: top left;
  transform: scale(1, 0);
  z-index: 1010;
  text-align: center;
  background: #f3f0eb;
}
.menu a {
  color: #000000;
}
.menu a:hover,
.menu a:active,
.menu a:focus-within {
  color: #ef5533;
  font-weight: 700;
}
.open {
  transform: scale(1);
  transition: 0.6s ease;
}
.nav-section h1 {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 1.2rem;
}
.nav-section .nav-icons {
  display: flex;
  gap: 0.45rem;
  align-items: center;
}
.nav-section .nav-icons > * {
  /* border: 1px solid teal; */
}
.nav-section .nav-icons > a {
  padding: 0.45rem;
  color: #000000;
}
.nav-section .user-icon {
  position: relative;
}

.nav-section .nested-icon-list {
  position: absolute;
  top: 100%;
  right: calc(100% - 1.6rem);
  text-align: center;
  z-index: 1010;
  border-radius: 4px;
  border: 1px solid #cbd8cc;
  background: #fff;
  /* padding: 1rem; */
}
.nav-section .dropdown-menu {
}

/* ===============cart styles========== */
.nav-section .cart-icon {
  position: relative;
}
.nav-section .cart-icon .cart-count {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  line-height: 18px;
  color: #fff;
  background-color: #ef5533;
  text-align: center;
}
/* DESKTOP SCREEN */
@media (min-width: 980px) {
  .nav-section .toggle-icon {
    display: none;
  }
  .nav-section .logo-text a {
    font-size: 1.85rem;
  }
  .menu {
    position: static;
    /* top: 100%; */
    /* left: 0; */
    width: auto;
    height: auto;
    transform-origin: initial;
    transform: initial;
  }
  .menu .menu-list {
    display: flex;
  }
  .nav-section .flex-container-2 > *:last-child {
    order: 3;
  }
  .nav-section .flex-container-2 > *:nth-child(3) {
    order: 4;
  }
  .nav-section .flex-container-2 {
    /* border: 1px solid red; */
  }
}
