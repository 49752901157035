.product-section {
  /* background: #fff; */
}
.product-section .card {
  background: #fff;
}
.product-section .card:hover {
  box-shadow: 0px 54px 80px -16px rgba(219, 222, 229, 0.8);
}
.product-section .card-header {
  background: rgba(242, 247, 251, 1);
  padding: 1.6rem;
}
.product-section .card-header.img-container {
  /* display: inline-block; */
  /* height: 280px; */
  /* overflow:hidden; */
}
.product-section .card-header.img-container img {
  /* height: fit-content; */
}
.product-section .card-footer {
  color: rgba(236, 42, 42, 1);
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
}

/* DISPLAY PRODUCTS STYLES */
.product-section.display-product .card {
  width: 280px;
  margin: auto;
}

.product-section.display-product .cta-btn-container {
  text-align: center;
}
