/* ABOUTUS STYLES */
.aboutus-section h2 {
  text-align: initial;
  font-weight: 700;
}
.aboutus-section p {
  margin: 2.4rem 0rem;
  font-size: 1.1rem;
}
.aboutus-section .image-overlay p {
  text-align: center;
}
.aboutus-section .image-overlay {
  position: relative;
  color: #fff;
  display: flex;
  width: fit-content;
  height: 350px;
}
.aboutus-section .image-overlay > * {
  margin: auto;
}
.aboutus-section .image-overlay::before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  background: url("../../public/assets/contact-hero-section.svg") no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
