/* RESET :: GOOGLE FONTS*/
@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

/* CuSTOME CSS IMPORT */
@import url("AboutUs.css");
@import url("Auth.css");
@import url("Contactus.css");
@import url("Home.css");
@import url("Menu.css");
@import url("NavBar.css");
@import url("Products.css");
@import url("HeroContents.css");
@import url("Subscribe.css");
@import url("StartYourJourney.css");
@import url("Testimonials.css");
@import url("SiteMetrics.css");
@import url("SearchPage.css");
@import url("Footer.css");
@import url("Card.css");
@import url("Cart.css");

/* GENERAL ELEMENTS STYLES */
html {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

img {
  display: block;
  width: 100%;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  display: block;
  padding: 0.7rem;
  text-transform: capitalize;
  color: #212121;
}

body {
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
  position: relative;
  background: #f3f0eb;
}
section {
  padding: 1.5rem 5%;
  /* border: 1px solid red; */
}
section:not(.subscribe-section, .footer-section, .nav-section) {
  margin-bottom: 3.4rem;
}
.subscribe-section {
  /* border: 1px solid green; */
}
h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: capitalize;
  margin-bottom: 1.2rem;
  font-family: "Inter", sans-serif;
}
p {
  font-family: "Inter", sans-serif;
  line-height: 1.8;
}
h2 {
  text-align: center;
  font-weight: 400;
  font-size: 1.8rem;
  text-transform: uppercase;
}
h3 {
  font-weight: 600;
  font-size: 1rem;
}
h6 {
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
  color: #676767;
}
h5 {
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
  color: #747789;
}
button {
  padding: 0.8rem 1.2rem;
  display: inline-block;
  background: #ef5533;
  box-shadow: none;
  border: none;
  color: #fff;
  border-radius: 30px;
  text-transform: capitalize;
  cursor: pointer;
}

/* SPINNER STYLES */
.spinner {
  animation: spin 1s linear infinite;
  /* font-size: 3rem;
  font-weight: 600;
  display: inline-block; */
}
.spin-container {
  /* display: block; */
}
.spin-container svg {
  width: 20px;
  height: 20px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* MODAL MESSAGE */
.message-modal {
  display: flex;
}
.modal-container {
  width: 80vw;
  box-shadow: var(--box-shadow-1);
  padding: 1rem;
  margin: auto;
}
.message-modal p {
  color: rgb(0, 128, 0);
  font-weight: 500;
  font-size: 1.2rem;
}

/* FORM ELEMENT STYLING */
form .form-field {
  position: relative;
  border-radius: 5px;
  margin-bottom: 2.5rem;
}
form .form-field label {
  text-transform: capitalize;
  margin-bottom: 0.45rem;
  font-weight: 400;
  display: block;
}
form .form-field input,
form .form-field textarea {
  width: 100%;
  padding: 0.85rem;
  border-radius: 6px;
  border: none;
  box-shadow: none;
  outline: none;
}

/* ICONS STYLES */
.nav-icon {
  width: 20.75px;
  height: 20.75px;
  /* display: inline-block; */
  cursor: pointer;
}
.user-icon.active {
  position: relative;
}
.user-icon.active::before {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  background-color: #228b22;
  border-radius: 50%;
  bottom: 3px;
  right: -3px;
  display: inline-block;
  z-index: 1040;
}

/* FOR FLEXED ITEMS */
.flex-container-2 > *,
.flex-container-4 > *,
.grid-container-3 > * {
  margin-bottom: 2.4rem;
}

.see-more a {
  color: rgba(236, 42, 42, 1);
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
}

/* DESKTOP SCREEN */
@media (min-width: 980px) {
  h2 {
    font-size: 2.8rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h6 {
    font-size: 0.875rem;
  }
  h5 {
    font-size: 1.2rem;
  }
  section {
    padding: 1.5rem 8%;
  }
  .flex-container-2 {
    display: flex;
    flex-flow: row;
    gap: 4%;
  }
  .flex-container-4 {
    display: flex;
    flex-flow: row wrap;
    gap: 4%;
  }
  .flex-container-4 > *,
  .flex-container-2 > * {
    flex-basis: 48%;
    margin-bottom: 0;
  }
  .grid-container-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .grid-container-3 > * {
    margin-bottom: 0;
  }
  .nav-icon {
    width: 25.75px;
    height: 25.75px;
  }
}

/* LARGE DESKTOP */
@media (min-width: 1200px) {
  .flex-container-4 > * {
    flex-basis: 22%;
    margin-bottom: 0;
  }
}
