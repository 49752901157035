.hero-section .site-caption h2 {
  font-weight: 400;
  /* font-size: 3.125rem; */
  font-size: 1.6rem;
  text-align: left;
}
.hero-section .site-caption h1 {
  font-weight: 700;
  /* font-size: 7.1875rem; */
  font-size: 4rem;
  text-transform: uppercase;
  text-align: left;
}

.hero-section h5 {
  text-align: left;
}
.hero-section .cta-btns {
  display: flex;
  flex-flow: row wrap;
  margin-top: 2rem;
  gap: 4%;
}
.hero-section .cta-btns > * {
  flex-basis: 48%;
  border: 1px solid #6e685e;
  /* padding: 0.7rem; */
  cursor: pointer;
  border-radius: 5px;
  font-size: 1.375rem;
  font-weight: 600;
  text-transform: capitalize;
  background: #f3f0eb;
  padding: 0rem;
}
.hero-section .cta-btns > :first-child {
  background: #ec2a2a;
  border: none;
}
.hero-section .cta-btns > :last-child a {
  color: #6e685e;
}
.hero-section .cta-btns > :first-child a {
  color: #fff;
}
.hero-section .flex-container-2 > *:last-child {
  position: relative;
}

/* DESKTOP STYLES */
@media (min-width: 980px) {
  .hero-section .site-caption h2 {
    /* font-size: 3.125rem; */
    font-size: 3rem;
  }
  .hero-section .site-caption h1 {
    /* font-size: 7.1875rem; */
    font-size: 7rem;
  }
}
